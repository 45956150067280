@charset "utf-8";

/* default */
html {/*height:100%;*/}
body {overflow-x:hidden;/*height:100%;*/font-family:'Poppins', 'Roboto', 'sans-serif';font-size:12px;line-height:1.3;color:#4b4c4c;background-color:#04031a;-webkit-font-smoothing:antialiased;-webkit-text-size-adjust:none;-moz-osx-font-smoothing:gratranslate3d(0,0,0)yscale;}
body,div,dl,dt,dd,ul,ol,li,h1,h2,h3,h4,h5,h6,form,fieldset,p,button,iframe,th,td{margin:0;padding:0;}
article,aside,details,figcaption,figure,footer,header,hgroup,nav,section,summary{display:block;margin:0;padding:0;}
h1,h2,h3,h4,h5,h6,dt{font-weight:normal;}
img,fieldset,iframe,button{border:0 none;}
button{cursor:pointer;background:none}
button:focus{outline:0;}
li{list-style:none;}
button{border:0 none}
table{ border-collapse:collapse;border-spacing:0; }
th, td{border-collapse:collapse;}
legend, caption, hr{display:none;}
select, input, textarea {}
i, em, u, cite {font-style:normal;}
strong{font-weight:normal;}
img{vertical-align:top;max-width:100%;}
a{color:#4b4c4c;text-decoration:none;outline-offset:-1px;}

/* common */
main {overflow-x:hidden;-webkit-overflow-scrolling:touch;}
section {max-width:1440px;margin:0 auto;}
#contents {position:relative;min-width:320px;min-height:100%;}
#contents a {transition:all .2s;-webkit-transition:all .2s;-ms-transition:all .2s;}
#contents a:hover,
#contents a:focus {opacity:.8;}

.btn-menu {display:none;}
.mobile-nav {display:none;}
.mobile {display:none;}
.br {display:block;}

.pop-open {overflow-y:hidden;}


.gradi-box div {position:absolute;z-index:-1;}
.gradi-box .left {left:0;}
.gradi-box .left01 {top:0;}
.gradi-box .left02 {top:4116px;}
.gradi-box .left03 {bottom:0;}
.gradi-box .right {right:0;}
.gradi-box .right01 {top:0;}
.gradi-box .right02 {top:2351px;}
.gradi-box .right03 {bottom:439px;}

/* header */
header {z-index:999;}
.header-inner {overflow:visible;max-width:1216px;margin:0 auto;padding:32px 0 28px;}
.header-inner:after {display:block;clear:both;content:'';}
.header-inner h1 {float:left;text-indent:-9999px;background:url('../src/assets/images/logo.png') 0 no-repeat;background-size:auto 48px;width:48px;height:48px;}
.header-inner h1 a {display:block;height:100%;transition:all .2s;-webkit-transition:all .2s;-ms-transition:all .2s;}
.header-inner h1 a:hover,
.header-inner h1 a:focus {opacity:.2;}
.header-inner nav {float:left;display:inline-block;vertical-align:middle;margin-left:100px;}
.header-inner .gnb {font-size:0;}
.header-inner .gnb li {position:relative;display:inline-block;vertical-align:middle;margin-left:40px;}
.header-inner .gnb a {position:relative;font-size:16px;line-height:48px;color:#9a99ad;}
.header-inner .select-box a {position:relative;}
.header-inner .select-box > div {display:none;overflow:hidden;width:86px;padding:8px 0;z-index:2;position:absolute;text-align:center;background:#574b87;border-radius:10px;}
.header-inner .select-box > div ul li {display:block;margin-left:0;}
.header-inner .select-box > div ul li a {display:block;font-family:'Poppins';font-size:14px;line-height:32px;color:#b3b2d4;}
.header-inner .select-box > div ul li a:hover {background:#fff;background:#392e64;}
.btn-wallet {float:left;padding:0 35px;margin-left:140px;border-radius:56px;box-shadow:0 13px 17px 0 rgba(119, 87, 239, 0.2);-webkit-box-shadow:0 13px 17px 0 rgba(119, 87, 239, 0.2);-ms-box-shadow:0 13px 17px 0 rgba(119, 87, 239, 0.2);background-image:linear-gradient(94deg, #3461ff 8%, #8454eb 93%);  font-size: 16px;font-weight:500;line-height:48px;letter-spacing:0.32px;color:#fff;}
.gnb li.on a:after {content:'';position:absolute;bottom:-20px;left:50%;margin-left:-15px;background:url('../src/assets/images/bg_gnb-indi.png') no-repeat;background-size:auto 21px;width:30px;height:21px;}
.btn-wallet:hover {opacity:.8;}
.tit {position:relative;font-size:40px;font-weight:600;line-height:1.4;letter-spacing:2px;color:#fff;}
.tit.text-c {text-align:center;}
h3 {font-size:40px;font-weight:600;line-height:1.4;letter-spacing:2px;text-align:center;color:#fff;}

.sub-tit {display:block;margin-top:24px;font-size:32px;line-height:1.25;color:#19171c;}
.sub-tit em {font-family:'Montserrat';font-weight:bold;color:#feac09;}
.desc {margin-top:40px;font-size:16px;line-height:1.38;color:#3f4246;word-break:keep-all;}
.color-y {color:#feac09;}
.flex {display:flex;align-items:center;justify-content:space-between;}

/* content */

.btn-line {
  display:inline-block;margin-top:32px;border-radius:56px;
  box-shadow:0 13px 17px 0 rgba(119, 87, 239, 0.2);
  -webkit-box-shadow:0 13px 17px 0 rgba(119, 87, 239, 0.2);
  -ms-box-shadow:0 13px 17px 0 rgba(119, 87, 239, 0.2);
  background-image: linear-gradient(#04031a, #04031a), linear-gradient(94deg, #3461ff 8%, #8454eb 93%);
  border:2px solid transparent;background-origin:border-box;background-clip:content-box, border-box;
  font-size:16px;font-weight:500;line-height:44px;letter-spacing:0.32px;color:#fff;
}
.btn-line span {padding:0 44px;}
.btn-full {
  display:inline-block;padding:0 80px;border-radius:56px;
  box-shadow:0 13px 17px 0 rgba(119, 87, 239, 0.2);
  -webkit-box-shadow:0 13px 17px 0 rgba(119, 87, 239, 0.2);
  -ms-box-shadow:0 13px 17px 0 rgba(119, 87, 239, 0.2);
  background-image:linear-gradient(94deg, #3461ff 8%, #8454eb 93%);
  font-size:16px;font-weight:500;line-height:48px;letter-spacing:0.32px;color:#fff;
}

.kv {position:relative;max-width:1106px;margin:0 auto;z-index:1;}
.kv .text {z-index:1;width:1106px;margin:0 auto;padding-top:141px;padding-bottom:192px;}
.kv .text > span {display:block;margin-top:60px;font-family:Roboto;font-size:18px;line-height:0.89;color:#848eaf;}
.kv .text div {margin-top:20px;font-family:Roboto;font-size:40px;font-weight:500;line-height:1;}
.kv .text .time {background-image:linear-gradient(93deg, #168bf8 3%, #46fab9 84%);letter-spacing:normal;-webkit-background-clip:text;background-clip:text;-webkit-text-fill-color:transparent;}
.kv figure div {overflow:hidden;width:520px;height:520px;position:absolute;top:20px;left:20px;border-radius:30px;}
.kv figure {position:absolute;top:100px;right:0; overflow: hidden;}
.kv video {position:absolute;top:50%;left:50%;/*width:520px;*/z-index:-1;transform:translate(-50%, -50%);-webkit-transform:translate(-50%, -50%);-ms-transform:translate(-50%, -50%);}


.feature01 {position:relative;text-align:center;padding-bottom:85px;}
.feature01 figure {object-fit:contain;mix-blend-mode:screen;}
.feature01 .tab {display:inline-block;margin-top:40px;padding-bottom:12px;border-bottom:2px solid #574b87;}
.feature01 .tab li {position:relative;margin:0 20px;}
.feature01 .tab li.curr:after {content:'';position:absolute;left:-20px;bottom:-14px;padding:0 20px;height:2px;width:100%;border-radius:4px;box-shadow:0 0 6px 3px rgba(106, 64, 246, 0.7), 0 0 2px 1px rgba(161, 0, 232, 0.5);-webkit-box-shadow:0 0 6px 3px rgba(106, 64, 246, 0.7), 0 0 2px 1px rgba(161, 0, 232, 0.5);-ms-box-shadow:0 0 6px 3px rgba(106, 64, 246, 0.7), 0 0 2px 1px rgba(161, 0, 232, 0.5);background:#cfbeff;}
.feature01 .tab li a {font-family:'Poppins';font-size:20px;font-weight:600;line-height:2;color:#574b87;}
.feature01 .tab .curr a {color:#fff;}
.feature01 .cont {display:none;}
.feature01 .cont.on {display:block;}
.feature01 p {margin-top:32px;font-size:16px;line-height:2;color:#b3b2d4;}
.feature01 ul {font-size:0;margin-top:40px;}
.feature01 li {display:inline-block;vertical-align:middle;}

.feature02 {padding-top:85px;}
.feature02 .inner-box {max-width:1216px;margin:0 auto;}
.feature02 ul {margin-top:72px;margin-bottom:32px;}
.feature02 li {display:flex;margin-top:64px;}
.feature02 li figure {flex:none;width:416px;}
.feature02 li figure img {margin:-32px;}
.feature02 .text {margin-left:32px;}
.feature02 .text strong {display:block;font-size:20px;font-weight:600;line-height:2;color:#fff;}
.feature02 .text p {margin-top:12px;font-size:16px;line-height:2;color:#b3b2d4;}
.feature02 .text a {margin-right:16px;}

.feature02 .btn-full {width:180px;box-sizing:border-box;-webkit-box-sizing:border-box;-ms-box-sizing:border-box;padding:0;text-align:center;}

.feature03 {position:relative;padding:220px 0 356px;}
.feature03 .inner-box {max-width:1216px;margin:0 auto;}
.feature03 .tit {position:relative;top:auto;left:auto;margin-bottom:40px;}
.feature03 ul li {display:block;margin-top:40px;}
.feature03 li .text span {font-size:12px;}
.feature03 li .text strong {margin-top:10px;font-size:40px;line-height:normal;}
.feature03 li figure {margin:0;}
/*.feature03 li:last-child {display:flex;flex-direction:column-reverse;align-items:flex-start;}*/
.feature03 .tit {position:absolute;top:0;left:0;}
.feature03 ul li {position:relative;display:flex;justify-content:space-between;align-items:center;margin-top:80px;font-family:'Poppins';}
.feature03 li .text span {display:block;font-size:24px;font-weight:500;line-height:1.67;color:#574b87;}
.feature03 li .text strong {display:block;margin-top:20px;font-size:56px;font-weight:600;line-height:0.71;color:#fff;}
.feature03 li figure {margin:-200px;}
.feature03 .btn-full {margin-top:32px;}

.feature04 {padding-bottom:200px;}
.feature04 .road-step ul {margin-top:72px;font-size:0;text-align:center;background:url('../src/assets/images/bg_line-dot.png') 50% 60px no-repeat;}
.feature04 .road-step li {display:inline-block;vertical-align:text-top;width:210px;margin-left:56px;padding-top:150px;font-size:20px;font-weight:600;line-height:1.6;color:#fff;}
.feature04 .road-step li:first-child {margin-left:0;}
.feature04 .ico01 {background:url('../src/assets/images/ico_roadmap01.png') 65% 0 no-repeat;}
.feature04 .ico02 {background:url('../src/assets/images/roadmap.png') 50% 0% no-repeat; background-size:120px 120px;}
.feature04 .ico03 {background:url('../src/assets/images/ico_roadmap03.png') 52% 0 no-repeat;}
.feature04 .ico04 {background:url('../src/assets/images/ico_roadmap04.png') 52% 0 no-repeat;}
.feature04 .ico05 {background:url('../src/assets/images/ico_roadmap05.png') 52% 0 no-repeat;}
.feature04 .car-box {position:relative;max-width:1216px;margin:0 auto;padding-top:52px;background:url('../src/assets/images/bg_line-car.png') 50% 100% no-repeat;}
.feature04 .car {position:absolute;bottom:0;}
.feature04 .road-step.step01 .car {left:2%;}
.feature04 .road-step.step02 .car {left:24%;}
.feature04 .road-step.step03 .car {left:46%;}
.feature04 .road-step.step04 .car {left:68%;}
.feature04 .road-step.step05 .car {left:90%;}
.feature04 .subscript {max-width:1216px;margin:40px auto 0;}
.feature04 .subscript li {font-size:16px;line-height:2;color:#b3b2d4;}

.feature05 {padding:63px 0 53px;width:1440px;height:324px;background:url('../src/assets/images/bg_marketplace.jpg') no-repeat;text-align:center;box-sizing:border-box;-webkit-box-sizing:border-box;-ms-box-sizing:border-box;}
.feature05 strong {display:block;width:620px;margin:0 auto;font-size:40px;font-weight:600;line-height:1.4;letter-spacing:2px;color:#fff;}
.feature05 .btn-full {margin-top:48px;padding:0 44px;}

.feature06 {display:flex;justify-content:space-between;max-width:1124px;margin:0 auto;padding:200px 0;}
.feature06 .tit {padding-top:20px;}
.feature06 .faq-wrap {width:100%;margin-left:115px;border-bottom:1px solid rgba(255, 255, 255, .3);}
.feature06 .tab {font-size:0;border-bottom:2px solid #574b87;padding-bottom:12px;margin-bottom:20px;}
.feature06 .tab li {position:relative;display:inline-block;margin-left:40px;}
.feature06 .tab li.curr:after {content:'';position:absolute;width:100%;height:2px;bottom:-14px;left:0;border-radius:4px;box-shadow:0 0 6px 3px rgba(106, 64, 246, 0.7), 0 0 2px 1px rgba(161, 0, 232, 0.5);-webkit-box-shadow:0 0 6px 3px rgba(106, 64, 246, 0.7), 0 0 2px 1px rgba(161, 0, 232, 0.5);-ms-box-shadow:0 0 6px 3px rgba(106, 64, 246, 0.7), 0 0 2px 1px rgba(161, 0, 232, 0.5);background:#cfbeff;}
.feature06 .tab li:first-child {margin-left:0;}
.feature06 .tab li a {font-family:'Poppins';font-size:20px;font-weight:600;line-height:2;color:#574b87;}
.feature06 .tab li.curr a {color:#fff;}
.feature06 .cont {display:none;}
.feature06 .cont.on {display:block;}
.feature06 .qna-list .question a {
  position:relative;display:box;display:-webkit-box;
  box-orient:vertical;-webkit-box-orient:vertical;
  box-pack:center;-webkit-box-pack:center;
  padding:33px 50px 40px 0;
  border-bottom:1px solid rgba(255, 255, 255, .3);
  font-size:22px;line-height:30px;color:#fff;
}
.feature06 .qna-list li:last-child a {border-bottom:0;}
.feature06 .qna-list .question a:after {content:'';position:absolute;top:50%;right:0;margin-top:-26px;width:51px;height:51px;background:url('../src/assets/images/ico_faq-show.png') 50% 50% no-repeat;background-size:auto 51px; cursor: pointer}
.feature06 .qna-list .question em {margin-right:30px;font-size:32px;text-transform:capitalize;background:linear-gradient(-45deg, #a03fd3, #4b2cb2, #4b2cb2);-webkit-background-clip:text;-webkit-text-stroke: 3px transparent;text-shadow:1px 1px 16px white;}
.feature06 .qna-list li.on .question a {border-bottom:none;}
.feature06 .qna-list li.on .question a:after {background:url('../src/assets/images/ico_faq-fold.png') 50% 50% no-repeat;background-size:auto 51px;}
.feature06 .qna-list .answer {display:none;padding:0 120px 28px 60px;font-size:16px;line-height:2;color:#b3b2d4;}
.feature06 .qna-list .answer p {margin-top:10px;}
.feature06 .qna-list .answer p:first-child {margin-top:0;}
.feature06 .qna-list li.on .answer {display:block;border-bottom:1px solid rgba(255, 255, 255, .3);}

.attributes {padding-top:120px;padding-bottom:148px;}
.attributes h3 + .attri-box {margin-top:120px;}
.attributes .attri-box {max-width:1060px;margin:80px auto 0;}
.attributes .attri-box strong {display:block;padding-left:48px;font-size:24px;font-weight:600;line-height:1.67;color:#fff;}
.attributes .attri-box ul {
  overflow:hidden;margin-top:32px;padding:48px;border-radius:32px;border:solid 2px #fff;
  box-shadow:0 0 8px 9px rgba(106, 64, 246, 0.7), 0 0 4px 4px rgba(161, 0, 232, 0.5), inset 0 0 3px 2px #ffc9fb;
  -webkit-box-shadow:0 0 8px 9px rgba(106, 64, 246, 0.7), 0 0 4px 4px rgba(161, 0, 232, 0.5), inset 0 0 3px 2px #ffc9fb;
  -ms-box-shadow:0 0 8px 9px rgba(106, 64, 246, 0.7), 0 0 4px 4px rgba(161, 0, 232, 0.5), inset 0 0 3px 2px #ffc9fb;
}
.attributes .attri-box ul:after {content:'';display:block;clear:both;}
.attributes .attri-box li {overflow:hidden;float:left;width:216px;margin-top:40px;margin-left:32px;border-radius:20px;}
.attributes .attri-box li:nth-child(n+1):nth-child(-n+4) {margin-top:0;}
.attributes .attri-box li:first-child,
.attributes .attri-box li:nth-child(4n+1) {margin-left:0;}
.attributes .attri-box li figure {background:#c4c4c4;height:162px;}
.attributes .attri-box .txt {position:relative;padding:15px 20px;background:#1e1c45;box-shadow:0 19.1px 13.5px 0 rgba(0, 0, 0, 0.16);-webkit-box-shadow:0 19.1px 13.5px 0 rgba(0, 0, 0, 0.16);-ms-box-shadow:0 19.1px 13.5px 0 rgba(0, 0, 0, 0.16);color:#fff;}
.attributes .attri-box .txt i {display:block;font-size:12px;line-height:17px;}
.attributes .attri-box .txt span {display:block;margin-top:8px;font-size:14px;}
.attributes .attri-box .txt .per {position:absolute;right:20px;bottom:15px;font-size:24px;font-weight:500;text-align:right;color:#fff;}
.txt-purple {color:#b3b2d4 !important;}
.txt-lemon {color:#f6eb97 !important;}
.txt-mint {color:#76d3ac !important;}
.txt-red {color:#ff6b6b !important;}
.txt-neon {text-shadow:0 2px 10px rgba(84, 216, 251, 0.99), 0 2px 20px #82d2ff;-webkit-text-shadow:0 2px 10px rgba(84, 216, 251, 0.99), 0 2px 20px #82d2ff;-ms-text-shadow:0 2px 10px rgba(84, 216, 251, 0.99), 0 2px 20px #82d2ff;}
.txt-rainbow {background-image:linear-gradient(96deg, #ff7979 1%, #efbf79 8%, #e1d27a 5%, #82d37a 12%, #7ab8b7 12%, #534bd1 18%, #ec69ff 24%, #ff7f7f 21%);-webkit-background-clip:text;background-clip:text;-webkit-text-fill-color:transparent;}

.minting {
  max-width:680px;margin:130px auto;padding:48px 0 72px;border-radius:32px;border:solid 2px #fff;text-align:center;
  box-shadow:0 0 8px 9px rgba(106, 64, 246, 0.7), 0 0 4px 4px rgba(161, 0, 232, 0.5), inset 0 0 3px 2px #ffc9fb;
  -webkit-box-shadow:0 0 8px 9px rgba(106, 64, 246, 0.7), 0 0 4px 4px rgba(161, 0, 232, 0.5), inset 0 0 3px 2px #ffc9fb;
  -ms-box-shadow:0 0 8px 9px rgba(106, 64, 246, 0.7), 0 0 4px 4px rgba(161, 0, 232, 0.5), inset 0 0 3px 2px #ffc9fb;
  box-sizing:border-box;-webkit-box-sizing:border-box;-ms-box-sizing:border-box;
}
.minting .mint-tit {font-size:24px;font-weight:600;line-height:1.67;color:#fff;}
.minting strong {display:block;margin-top:32px;font-size:20px;font-weight:500;color:#fff;}
.minting strong + span {margin-top:8px;}
.minting span {display:block;margin-top:20px;font-size:14px;font-weight:500;color:#b3b2d4;}
.minting .small {font-size:12px;font-weight:normal;}
.minting .red {color:#ff4735;}
.minting .white {color:#fff;}
.minting .thumb {width:216px;height:216px;margin:24px auto 0;background:#c4c4c4;border-radius:16px;}
.minting .btn-wallet {float:none;display:block;width:130px;margin:32px auto 0;}

/* popup */
/*.popup-wrap {position:fixed;top:0;left:0;width:100%;height:100%;z-index:9999;}
.popup-wrap > div {display:none;}
.popup-wrap .dim,
.popup-wrap > div {position:absolute;top:0;left:0;width:100%;height:100%;}
.popup-wrap .pop-video {position:absolute;top:0;left:0;width:100%;height:100%;}
.popup-wrap .dim {z-index:-1;}
.popup-wrap .pop-video .dim {background:rgba(0, 0, 0, .8);}
.popup-wrap .pop-video .pop-close {top:100px;right:100px;}
.popup-wrap .pop-close {position:absolute;z-index:1;}
.popup-wrap .pop-video {position:absolute;top:50%;left:50%;transform:translate(-50%, -50%);-webkit-transform:translate(-50%, -50%);-ms-transform:translate(-50%, -50%);width:960px;}
.popup-wrap .pop-close {position:absolute;top:100px;right:100px;z-index:1;}
.popup-wrap .pop-close img {height:50px;} */

.pop-edition {display:none;}
.pop-edition .edition-inner {
  position:absolute;width:712px;padding:16px;height:auto;top:140px;;left:50%;border-radius:40px;border:solid 2px #fff;
  transform:translateX(-50%);-webkit-transform:translateX(-50%);-ms-transform:translateX(-50%);
  box-shadow:0 0 8px 9px rgba(106, 64, 246, 0.7), 0 0 4px 4px rgba(161, 0, 232, 0.5), inset 0 0 3px 2px #ffc9fb;
  -webkit-box-shadow:0 0 8px 9px rgba(106, 64, 246, 0.7), 0 0 4px 4px rgba(161, 0, 232, 0.5), inset 0 0 3px 2px #ffc9fb;
  -ms-box-shadow:0 0 8px 9px rgba(106, 64, 246, 0.7), 0 0 4px 4px rgba(161, 0, 232, 0.5), inset 0 0 3px 2px #ffc9fb;
  box-sizing:border-box;-webkit-box-sizing:border-box;-ms-box-sizing:border-box;
}
.pop-edition .dim {height:150%;-webkit-backdrop-filter:blur(10px);backdrop-filter:blur(10px);background-color:rgba(44, 42, 51, 0.5);}
.pop-edition .pop-close {top:48px;right:48px;}
.pop-edition .edition-wrap {padding:72px 56px 54px;border-radius:32px;box-shadow:inset 0 0 3px 2px #ffc9fb;-webkit-box-shadow:inset 0 0 3px 2px #ffc9fb;-ms-box-shadow:inset 0 0 3px 2px #ffc9fb;background:#04031a;}
.pop-edition .txt-rainbow {background-image:linear-gradient(98deg, #ff7979 4%, #efbf79 18%, #e1d27a 31%, #82d37a 44%, #7ab8b7 61%, #534bd1 74%, #ec69ff 88%, #ff7f7f 98%);}
.pop-edition h4 {text-shadow:0 0 6px #a03fd3, 0 0 2px #4b2cb2, 0 0 2px #4b2cb2;-webkit-text-stroke:0.5px rgba(167, 44, 178, 0.37);font-size:26px;font-weight:600;line-height:1.54;color:#fff;}
.pop-edition p {margin-top:20px;font-size:14px;line-height:1.71;color:#b3b2d4;}
.pop-edition .edit-cont01 {margin:40px 0;padding:40px 0 32px;border-top:1px solid #574b87;border-bottom:1px solid #574b87;}
.pop-edition .small-tit {font-size:18px;font-weight:600;line-height:2.22;color:#fff;}
.pop-edition li {margin-top:16px;font-size:14px;line-height:1.71;}
.pop-edition li:first-child {margin-top:20px;}
.pop-edition li strong {display:inline-block;vertical-align:text-top;width:113px;font-weight:500;color:#fff;}
.pop-edition li span {display:inline-block;vertical-align:text-top;max-width:440px;color:#b3b2d4;}
.pop-edition li i {display:inline-block;margin-right:8px;margin-bottom:6px;}
.pop-edition .edit-cont02 .table-box {margin-top:20px;border-radius:16px;border:solid 1px #574b87;}
.pop-edition .edit-cont02 table {display:table;table-layout:fixed;width:100%;}
.pop-edition .edit-cont02 th {font-size:12px;font-weight:600;line-height:52px;color:#9391c7;border-bottom:solid 1px #574b87;}
.pop-edition .edit-cont02 td:not(td:first-child) {font-size:12px;line-height:54px;text-align:center;color:#fff;}
.pop-edition .edit-cont02 td:first-child {padding-left:20px;}
.pop-edition .edit-cont02 .txt-neon {color:#fff;}
.pop-edition .edit-cont02 tbody tr:nth-child(odd) {background:#14112f;}
.pop-edition .edit-cont02 tfoot {border-top:solid 1px #574b87;}
.pop-edition .edit-cont02 tfoot td {font-weight:600;}
.pop-edition .edit-cont02 tfoot td:first-child {color:#9391c7;text-align:center;}


/* footer */
footer {border-top:1px solid #393759;}
footer .inner-box {display:flex;justify-content:space-between;align-items:center;max-width:1220px;margin:0 auto;padding:32px 0;}
footer .logo img {height:52px;}
footer ul {font-size:0;}
footer li {display:inline-block;vertical-align:middle;margin-left:24px;}
footer li img {height:40px;}


/* mobile web */

@media (max-width: 1280px) {
  #contents {max-width:375px;margin:0 auto;}
  html.nav-open {overflow-y:hidden;}
  header {display:none;}
  .pc {display:none;}
  .mobile {display:block;}

  .btn-menu {display:block;position:absolute;top:40px;left:24px;width:40px;height:40px;z-index:9999;background:url('../src/assets/images/btn_menu.png') no-repeat;background-size:auto 40px;text-indent:-9999px;}
  .mobile-nav {overflow-y:scroll;box-sizing:border-box;-webkit-box-sizing:border-box;-ms-box-sizing:border-box;display:block;padding:118px 72px 0 72px;position:fixed;top:0;left:-100%;height:100%;width:100%;background:#04031a url('./assets/images/bg_mobile-menu.png') no-repeat;z-index:9999;background-size:cover;}
  .mobile-nav {overflow-y:scroll;box-sizing:border-box;-webkit-box-sizing:border-box;-ms-box-sizing:border-box;display:block;padding:118px 72px 0 72px;position:fixed;top:0;left:-100%;left:0;height:100%;width:100%;background:#04031a url('./assets/images/bg_mobile-menu.png') no-repeat;z-index:9999;background-size:cover;}
  .mobile-nav .close {position:absolute;top:32px;right:24px;}
  .mobile-nav .close img {height:40px;}
  .mobile-nav .btn-sns {position:absolute;bottom:56px;left:64px;}
  .mobile-nav .btn-sns ul {font-size:0;}
  .mobile-nav .btn-sns li {display:inline-block;vertical-align:middle;margin-right:24px;}
  .mobile-nav .btn-sns li img {height:24px;}


  .mobile-nav .gnb li:first-child {margin-top:0;}
  .mobile-nav .gnb li {position:relative;margin-top:32px;}
  .mobile-nav .gnb li a {font-size:20px;line-height:1.7;color:#9a99ad;}
  .mobile-nav .gnb li a:hover,
  .mobile-nav .gnb li a:focus {color:#81c00c}
  .mobile-nav .gnb li .btn-wallet {display:block;float:none;margin-left:0;font-size: 16px;font-weight:500;line-height:48px;letter-spacing:0.32px;color:#fff;text-align:center;}
  .mobile-nav .gnb li .btn-wallet:hover:active {
    opacity: 0.8;
  }


  .tit {font-size:24px;line-height:1.33;letter-spacing:normal;}
  h3 {font-size:24px;letter-spacing:normal;text-align:left;}

  .mobile-br {display:block;}


  .gradi-box .left01 {display:none;}
  .gradi-box .left02 {top:4116px;}
  .gradi-box .left03 {bottom:0;}
  .gradi-box .right01 {top:0;}
  .gradi-box .right01 img {height:652px}
  .gradi-box .right02 {top:2351px;}
  .gradi-box .right03 {bottom:439px;}

  /* content */
  .btn-line {
    margin-top:16px;border-radius:32px;
    box-shadow:0 7px 9px 0 rgba(119, 87, 239, 0.2);
    -webkit-box-shadow:0 7px 9px 0 rgba(119, 87, 239, 0.2);
    -ms-box-shadow:0 7px 9px 0 rgba(119, 87, 239, 0.2);
    border:1px solid transparent;background-origin:border-box;background-clip:content-box, border-box;
    font-size:14px;line-height:44px;letter-spacing:0.28px;
  }
  .btn-line span {padding:0 22px;}
  .btn-full {
    padding:0 44px;border-radius:32px;
    box-shadow:0 7px 9px 0 rgba(119, 87, 239, 0.2);
    -webkit-box-shadow:0 7px 9px 0 rgba(119, 87, 239, 0.2);
    -ms-box-shadow:0 7px 9px 0 rgba(119, 87, 239, 0.2);
    font-size:14px;letter-spacing:0.28px;
  }

  .kv {max-width:none;padding:0 24px;}
  .kv .text {width:auto;padding-top:136px;padding-bottom:20px;}
  .kv .text strong {display:block;}
  .kv .text strong img {height:160px;}
  .kv .text > span {margin-top:32px;font-size:14px;line-height:1.14;}
  .kv .text div {margin-top:10px;font-size:28px;line-height:1.43;}
  .kv figure {position:relative;top:auto;right:auto;}
  .kv figure img {height:353px;}
  .kv figure div {width:302px;height:328px;top:12px;left:12px;border-radius:15px;}


  .feature01 {padding-top:67px;padding-bottom:80px;}
  .feature01 figure img {height:156px;}
  /*.feature01 .tit {top:170px;}*/
  .feature01 p {margin-top:24px;padding:0 24px;font-size:14px;line-height:1.86;}
  .feature01 ul {margin-top:32px;padding:0 16px;overflow-x:scroll;white-space:nowrap;}
  .feature01 .tab {overflow:initial;padding:0 0 12px 0;}
  .feature01 li img {height:200px;}

  .feature02 {padding:0 24px;}
  .feature02 .inner-box {max-width:none;}
  .feature02 ul {margin-top:24px;margin-bottom:0;}
  .feature02 li {display:block;margin-top:40px;}
  .feature02 li figure {width:auto;}
  .feature02 li figure img {margin:-16px;height:297px;}
  .feature02 .text {margin-left:0;margin-top:16px;}
  .feature02 .text p {margin-top:16px;font-size:14px;line-height:1.86;}
  .feature02 .btn-full {width:126px;}

  .feature03 {padding:80px 0 80px;}
  .feature03 .inico02ner-box {max-width:none;}
  .feature03 .tit {position:relative;top:auto;left:auto;margin-bottom:40px;}
  .feature03 ul li {display:block;margin-top:40px;}
  .feature03 li .text span {font-size:12px;}
  .feature03 li .text strong {margin-top:10px;font-size:40px;line-height:normal;}
  .feature03 li figure {margin:0;}
  .feature03 li:last-child {display:flex;flex-direction:column-reverse;align-items:flex-start;}
  .feature03 li:last-child .text {padding-left:24px;}
  .feature03 .text {padding:0 24px;}
  .feature03 figure {position:relative;top:auto;right:auto;margin-right:-200px;}
  .feature03 strong {margin-top:40px;width:212px;font-size:40px;line-height:1.4;}
  .feature03 .btn-full {margin-top:16px;}

  .feature04 {padding:0 24px 80px;}
  .feature04 .tit {text-align:left;}
  .feature04 .road-step ul {margin-top:58px;text-align:left;background:url('../src/assets/images/bg_line-dot-m.png') 11% 55px no-repeat;}

  .feature04 .road-step li {display:block;margin-top:80px;width:auto;min-height:80px;margin-left:0;padding-top:0;padding-left:104px;font-size:16px;}
  .feature04 .road-step li div {display:table-cell;vertical-align:middle;height:80px;}
  .feature04 .road-step li:first-child {margin-top:0;}
  .feature04 .ico01 {background:url('../src/assets/images/ico_roadmap01.png') 0 0 no-repeat;background-size:auto 80px;}
  .feature04 .ico02 {background:url('../src/assets/images/roadmap.png') 0 0 no-repeat;background-size:70px 80px;}
  .feature04 .ico03 {background:url('../src/assets/images/ico_roadmap03.png') 0 0 no-repeat;background-size:auto 80px;}
  .feature04 .ico04 {background:url('../src/assets/images/ico_roadmap04.png') 0 0 no-repeat;background-size:auto 80px;}
  .feature04 .ico05 {background:url('../src/assets/images/ico_roadmap05.png') 0 0 no-repeat;background-size:auto 80px;}
  .feature04 .car-box {display:none;}

  .feature04 .subscript {max-width:none;}
  .feature04 .subscript li {font-size:12px;line-height:1.67;}

  .feature05 {padding:32px 50px 36px;width:375px;height:296px;background:url('../src/assets/images/bg_marketplace-m.jpg') no-repeat;text-align:left;}
  .feature05 strong {width:251px;font-size:28px;margin:0;letter-spacing:normal;text-align:left;}
  .feature05 .btn-full {margin-top:20px;padding:0 24px;}

  .feature06 {display:block;max-width:none;padding:80px 24px;}
  .feature06 .tit {padding-top:0;}
  .feature06 .faq-wrap {margin-top:15px;margin-left:0;padding-left:40px;box-sizing:border-box;-webkit-box-sizing:border-box;-ms-box-sizing:border-box;border-bottom:none;}
  .feature06 .tab {margin-left:-40px;}
  /*.feature06 ul {margin-top:15px;margin-left:0;padding-left:60px;box-sizing:border-box;-webkit-box-sizing:border-box;-ms-box-sizing:border-box;border-bottom:none;}*/
  .feature06 .qna-list .question a {padding:20px 0;font-size:18px;line-height:26px;}
  .feature06 .qna-list li:last-child a {border-bottom:1px solid rgba(255, 255, 255, .3);}
  .feature06 .qna-list .question a:after {display:none;}
  .feature06 .qna-list .question em {margin-right:0;font-size:18px;position:absolute;top:20px;left:-38px;}
  .feature06 .qna-list .answer {display:none;padding:0 0 20px 0;font-size:14px;line-height:1.86;}
  .feature06 .qna-list .answer p {margin-top:10px;}
  .feature06 .qna-list .answer p:first-child {margin-top:0;}
  .feature06 .qna-list li.on .answer {display:block;border-bottom:1px solid rgba(255, 255, 255, .3);}

  .attributes {padding-bottom:80px;}
  .attributes h3 {width:200px;padding:0 24px;}
  .attributes h3 + .attri-box {margin-top:40px;}
  .attributes .attri-box {margin:56px auto 0;padding:0 24px;}
  .attributes .attri-box strong {padding-left:0;font-size:18px;line-height:2.22;}
  .attributes .attri-box ul {margin-top:24px;padding:48px 55px;}
  .attributes .attri-box li {float:none;margin-top:20px;margin-left:0;}
  .attributes .attri-box li:nth-child(n+1):nth-child(-n+4) {margin-top:20px;}
  .attributes .attri-box li:first-child {margin-top:0 !important;}

  .pop-edition .edition-inner {transform:none;top:24px;left:0;width:auto;padding:12px;margin:0 20px;}
  .pop-edition .dim {height:200%;}
  .popup-wrap .pop-edition .pop-close {top:28px;right:28px;}
  .popup-wrap .pop-edition .pop-close.big {top:auto;bottom:-88px;right:auto;left:50%;margin-left:-28px;}
  .popup-wrap .pop-edition .pop-close.big img {height:56px;}

  .pop-edition .edition-wrap {padding:84px 20px 22px;}

  .pop-edition h4 {width:200px;}
  .pop-edition p {margin-top:32px;}
  .pop-edition li span {max-width:140px;color:#b3b2d4;}

  .pop-edition .edit-cont02 .table-box {overflow:hidden;}
  .pop-edition .edit-cont02 .table-box li {margin-top:0;padding:20px 16px 0;font-size:13px;line-height:1.85;}
  .pop-edition .edit-cont02 .table-box li:nth-child(odd) {background:#14112f;}

  .pop-edition .edit-cont02 th {font-size:11px;font-weight:600;line-height:2.18;border-bottom:none;}
  .pop-edition .edit-cont02 td:not(td:first-child) {font-size:13px;line-height:1.85;text-align:left;color:#fff;}
  .pop-edition .edit-cont02 td:first-child {padding-left:0;}
  .pop-edition .edit-cont02 td {padding-bottom:20px;color:#fff;}
  .pop-edition .edit-cont02 tbody tr:nth-child(odd) {background:none;}

  .pop-edition .edit-cont02 tfoot {border-top:solid 1px #574b87;}
  .pop-edition .edit-cont02 tfoot td {font-weight:600;}
  .pop-edition .edit-cont02 tfoot td:first-child {color:#9391c7;text-align:center;}

  /*.popup-wrap .pop-video {width:360px;}*/
  .popup-wrap {overflow-y:scroll;}
  .popup-wrap .pop-close {top:50px;right:50px;}
  .popup-wrap .pop-close img {height:40px;}


  /* footer */
  footer .inner-box {max-width:none;padding:20px 24px;}
  footer .logo img {height:41px;}
  footer li {margin-left:16px;}
  footer li img {height:24px;}


}

